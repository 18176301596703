<template>
  <div class="main-box">
    <div class="main-box-container">
      <split-pane :horizontal="false">
        <template #pane1>
          <div class="tree-box">
            <div class="tree-box-header">
              <div class="header-btns">
                <el-button type="primary" @click="getDirectory" class="open-btn">打开文件夹</el-button>
                <el-button type="primary" @click="formatCode" class="open-btn">格式化</el-button>
              </div>
            </div>
            <el-tree
              :data="fileList"
              :props="defaultProps"
              @node-click="handleNodeClick"
              empty-text
              class="custom-tree"
            ></el-tree>
            <el-dropdown class="menu-list" trigger="click">
              <span class="el-dropdown-link">{{ language }}</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in languageList" :key="item">
                  <div @click="clickLanguage(item)">{{ item }}</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
        <template #pane2>
          <code-editor ref="editorCode" v-model="fileContent" :language="language"></code-editor>
        </template>
      </split-pane>
    </div>
  </div>
</template>

<script>
import codeEditor from "@components/codeEditor";
import splitPane from "@components/splitPane";
export default {
  name: "codePage",
  components: {
    codeEditor,
    splitPane
  },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "name"
      },
      fileList: [],
      fileContent: "",
      language: "html",
      languageList: [
        "javascript",
        "typescript",
        "css",
        "less",
        "php",
        "sql",
        "scss",
        "html",
        "go",
        "python",
        "java"
      ]
    };
  },
  methods: {
    /**
     * 获取目录
     */
    async getDirectory() {
      const handle = await window.showDirectoryPicker();
      await processHandle(handle);
      this.fileList = [handle];

      async function processHandle(handle) {
        if (handle.kind === "file") {
          return;
        }
        handle.children = [];
        const iter = await handle.entries();
        for await (const entry of iter) {
          const subHandle = entry[1];
          handle.children.push(subHandle);
          await processHandle(subHandle);
        }
      }
    },
    /**
     * 选择文件
     */
    async handleNodeClick(data) {
      if (data.kind === "directory") {
        return;
      }
      const file = await data.getFile();
      const reader = new FileReader();
      reader.onload = e => {
        this.fileContent = e.target.result;
        console.log(this.fileContent);
      };
      reader.readAsText(file);
    },
    /**
     * 选择语言
     */
    clickLanguage(item) {
      this.language = item;
    },
    /**
     * 格式化代码
     */
    formatCode() {
      this.$refs.editorCode.formatCode();
    }
  }
};
</script>

<style lang="less" scoped>
.main-box {
  .main-box-container {
    height: calc(100vh - 150px);
    /deep/ .splitpanes__splitter {
      width: 2px !important;
    }
  }
  .tree-box {
    position: relative;
    width: 100%;
    height: 100%;
    background: #1f1f1f;
    .tree-box-header {
      display: flex;
      justify-content: center;
      align-items: center;
      .header-btns {
        width: 100%;
        display: flex;
        .open-btn {
          display: block;
          // width: 60%;
          color: #ffffff;
          margin: 12px 6px 12px 0;
        }
      }
      .menu-list {
        color: #ffffff;
      }
    }
    .custom-tree {
      color: #ffffff;
      background: #1f1f1f;
    }
    .menu-list {
      position: absolute;
      right: 8px;
      bottom: 8px;
      color: #cccccc;
    }
  }
}
/* 使用深度选择器覆盖默认样式 */
::v-deep .el-tree-node__content:hover {
  background-color: transparent !important; /* 鼠标悬停时不显示背景色 */
}

::v-deep .el-tree-node__content.is-current {
  background-color: #37373d !important; /* 当前激活节点不显示背景色 */
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #37373d !important;
}
::v-deep .el-tree {
  background-color: transparent !important;
}
</style>