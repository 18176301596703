<template>
  <div ref="main" style="width: 100%; height: 100%;"></div>
</template>

<script>
import * as monaco from "monaco-editor/esm/vs/editor/editor.api.js";
import "monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution"; // 代码高亮
import "monaco-editor/esm/vs/editor/contrib/find/findController.js"; // 引入查找控件
export default {
  name: "codeEditor",
  props: {
    language: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (this.editor && this.editor.getValue() !== newValue) {
          this.editor.setValue(newValue);
        }
      },
      immediate: true
    },
    language: {
      handler(newLanguage) {
        if (this.monacoEditor) {
          const model = this.monacoEditor.getModel();
          if (model) {
            monaco.editor.setModelLanguage(model, newLanguage);
          }
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      monacoEditor: null
    };
  },
  methods: {
    initMonaco() {
      this.monacoEditor = monaco.editor.create(this.$refs.main, {
        theme: "vs-dark",
        value: this.value,
        language: this.language,
        folding: true,
        foldingHighlight: true,
        foldingStrategy: "auto",
        showFoldingControls: "always",
        disableLayerHinting: true,
        emptySelectionClipboard: false,
        selectionClipboard: false,
        automaticLayout: true,
        codeLens: true,
        scrollBeyondLastLine: false,
        colorDecorators: true,
        accessibilitySupport: "on",
        lineNumbersMinChars: 4,
        enableSplitViewResizing: false,
        readOnly: false,
        fontSize: 14
      });
      this.monacoEditor.onDidChangeModelContent(() => {
        this.$emit("change", this.monacoEditor.getValue());
      });
    },
    formatCode() {
      if (this.monacoEditor) {
        this.monacoEditor.trigger("editor", "editor.action.formatDocument");
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initMonaco();
    });
  }
};
</script>