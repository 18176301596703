<template>
  <splitpanes :horizontal="horizontal" class="default-theme">
    <pane min-size="20" size="20">
      <slot name="pane1"></slot>
    </pane>
    <pane min-size="20">
      <slot name="pane2"></slot>
    </pane>
  </splitpanes>
</template>

<script>
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

export default {
  name: "splitPane",
  components: {
    Splitpanes,
    Pane
  },
  props: {
    horizontal: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="less" scoped>
.splitpanes__pane {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica, Arial, sans-serif;
  color: rgba(255, 255, 255, 0.6);
  font-size: 2em;
}
</style>